import React from "react"
import s from "./credit.module.css"
import { MdCameraAlt } from "react-icons/md"

const Credit = ({ name, link }) => {
    return (
        <div></div>
    )
}

export default Credit
